import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import previewImage from "../../assets/images/eow-logo.png";
import siteLanguages from "../../utils/enums/languages";

function SEO({ description, lang, meta, title, keywords, languages }) {
  const { site  } = useStaticQuery(
    graphql` 
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        } 
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description
  var urlTitle = ""
  if (typeof window !== "undefined") {
    urlTitle = window?.location?.href
  }

const languageCodeRegex = /\/([a-z]{2})\//; 
const matches = urlTitle.match(languageCodeRegex);
let currentLangCode = matches && matches[1]; 
if(currentLangCode === '' || currentLangCode === null){
  currentLangCode = 'en'
}
  const metaData = [
    {
      name: `twitter:creator`,
      content: site.siteMetadata?.author || ``,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:card`,
      content: "summary_large_image",
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: `twitter:site`,
      content: 'eraofweofficial'
    },
    {
      name: `twitter:image`,
      content: 'https://www.eraofwe.com/static/era-we-black-317321c5659acc8079817f855eb1fee2.svg'
    },
    {
      name: "keywords",
      content: keywords,
    },
    {
      name: "ahrefs-site-verification",
      content:
        "62b049ad52a20321e5077e5a343904cc5d2e60d6413475990e5372d5000629f3",
    },
    {
      name: "facebook-domain-verification",
      content: "h13024xxnk0r1hdptjpb1r4lvhibaz",
    },
    {
      name: 'description',
      content: metaDescription
    },
    {
      name: "og:title",
      content: title
    },
    {
      name: "og:url",
      content: urlTitle
    },
    {
      name: "og:type",
      content: "website"
    },
    {
      name: "og:description",
      content: metaDescription
    },
    {
      name: "og:image",
      content: previewImage
    },
  ].concat(meta);
 

  return (
    <>
      <Helmet htmlAttributes={{ lang: currentLangCode  }} title={title} titleTemplate={`%s - Era of We`}>
        {
          metaData.map((m, i) => (
            <meta key={i} name={m.name} content={m.content} />
          ))
        }
        {
          process.env.GATSBY_NEED_PROTECT === 'true'
          && (
            <meta name="robots" content="noindex, nofollow" />
          )
        }
        {
          languages && siteLanguages.length > 1 &&
            languages.map((l, i) => (
                  <link key={i} rel="alternate" hreflang={l.hrefLang} href={l.href} />
                ))
        }
        <script
          src="https://code.jquery.com/jquery-3.4.1.min.js"
          integrity="sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo="
          crossorigin="anonymous"
        ></script>
      </Helmet>
    </>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
